import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import logger from './logger';

export type ContentType = 'text/csv' | 'application/pdf' | 'application/zip';
type FileContent = Blob | Uint8Array | string;

export default class S3Uploader {
    private static instance: S3Uploader;
    private readonly s3Client: S3Client;

    private constructor() {
        const region = process.env.AWS_REGION || 'us-east-1';
        // create client with key and secret
        this.s3Client = new S3Client({ region });
    }

    public static getInstance(): S3Uploader {
        if (!S3Uploader.instance) {
            S3Uploader.instance = new S3Uploader();
        }
        return S3Uploader.instance;
    }

    public async uploadReport({
        companyId,
        fileName,
        fileContent,
        contentType
    }: {
        companyId: string;
        fileName: string;
        fileContent: FileContent;
        contentType: ContentType;
    }): Promise<string> {
        const bucketName = process.env.BUCKET_SYSTEM_GENERATED_CONTENT;
        if (!bucketName) {
            throw new Error('Missing environment variable: BUCKET_SYSTEM_GENERATED_CONTENT');
        }
        const sanitizedFileName = fileName.replace(/[^\w\s.-]/g, '').replace(/\s+/g, '_');
        const key = `sg/company/${companyId}/reports/${sanitizedFileName}`;
        return this.uploadFile({
            bucketName,
            fileContent,
            contentType,
            key
        });
    }

    /**
     * Uploads a file to S3 and generates a pre-signed URL.
     * @param bucketName - Name of the S3 bucket to upload the file to.
     * @param fileContent - Content of the file to upload (as a Blob, Uint8Array, or string).
     * @param contentType - Content type of the file.
     * @param contentDisposition
     * @param key - Key (name) of the file in S3.
     * @returns The S3 URI of the uploaded file.
     */
    private async uploadFile({
        bucketName,
        fileContent,
        contentType,
        key
    }: {
        bucketName: string;
        fileContent: FileContent;
        contentType: ContentType;
        key: string;
    }): Promise<string> {
        try {
            // Upload file to S3
            const putCommand = new PutObjectCommand({
                Bucket: bucketName,
                Key: key,
                Body: fileContent,
                ContentType: contentType
            });
            await this.s3Client.send(putCommand);
            return `s3://${bucketName}/${key}`;
        } catch (error) {
            logger.error({ err: error, bucketName, key, contentType }, 'Failed to upload file to S3');
            throw error;
        }
    }
}
