import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import appConfig from 'config/appConfig';
import getFingerprint from 'lib/fingerprint';
import logger from '@finance/shared/dist/utils/logger';
import SetupResourcesPlayer from 'components/players/SetupResourcesPlayer';
import { appLoadedMessage, appRenderedMessage } from 'lib/client/services/postMessageService';
import { NextPage } from 'next';
import { trackError } from 'lib/error.utils';
import styled from 'styled-components';

export type HBLoginMessage = {
    event: 'hb-finance-login';
    payload?: HBLoginParams;
};

type HBLoginParams = {
    userId: string;
    companyId: string;
    token: string;
    admin: boolean;
    callbackUrl?: string;
    auditeeCompanyId?: string;
};

interface IProps {
    isAuthDisabled?: boolean;
}

const Token: NextPage<IProps> = ({ isAuthDisabled = false }) => {
    const router = useRouter();

    const buildCallbackUrl = useCallback(() => {
        const { callbackUrl, source } = router.query;

        let fullCallbackUrl = callbackUrl as string;
        if (source) {
            const url = new URL(fullCallbackUrl, window.location.origin);
            url.searchParams.append('source', source as string);
            fullCallbackUrl = url.toString();
        }

        return fullCallbackUrl;
    }, [router.query]);

    const signInDev = useCallback(async () => {
        const callbackUrl = buildCallbackUrl();
        await signIn('hbTokenAuthenticationDev', {
            fingerprint: await getFingerprint(),
            admin: false,
            redirect: !!callbackUrl,
            callbackUrl: callbackUrl as string
        });
    }, [buildCallbackUrl]);

    useEffect(() => {
        if (!router.isReady) return;
        appRenderedMessage();
        const handleMessage = async (message: MessageEvent<HBLoginMessage>) => {
            if (!message?.data) {
                return;
            }
            const { event, payload } = message.data;
            if (event === 'hb-finance-login' && payload) {
                const data = message.data.payload as HBLoginParams;
                data.callbackUrl = buildCallbackUrl();
                await signInWithToken(data);
            }
        };

        // dev login
        if (isAuthDisabled) {
            signInDev();
        }

        window.addEventListener('message', handleMessage);
        return () => {
            return window.removeEventListener('message', handleMessage);
        };
    }, [router.isReady, router, signInDev, isAuthDisabled, buildCallbackUrl]);

    return (
        <LoaderWrapper>
            <SetupResourcesPlayer />
        </LoaderWrapper>
    );
};

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - var(--topbar-height));
`;

const signInWithToken = async ({ userId, companyId, token, admin, callbackUrl, auditeeCompanyId }: HBLoginParams) => {
    if (!token || !userId || !companyId) {
        logger.warn('missing credentials');
        appLoadedMessage(false);
        return;
    }
    try {
        logger.info('signing in');
        await signIn('hbTokenAuthentication', {
            userId,
            companyId,
            token,
            fingerprint: await getFingerprint(),
            admin: admin ? 'admin' : '',
            redirect: !!callbackUrl,
            callbackUrl,
            auditeeCompanyId: auditeeCompanyId ?? ''
        });

        appLoadedMessage(false);
    } catch (error) {
        logger.error({ error }, 'failed to authenticate with hb token');

        if (error instanceof Error) {
            trackError({
                error,
                message: 'failed to authenticate with hb token'
            });
        }
        appLoadedMessage(false);
    }
};

export const getServerSideProps = async () => {
    const { isDevelopmentEnv, isAuthDisabled } = appConfig;

    return {
        props: {
            isAuthDisabled: isDevelopmentEnv && isAuthDisabled()
        }
    };
};

export default Token;
